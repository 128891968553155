/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  Container,
  GroupItem,
  BtnGroupContainer,
  TableEmpty,
  CloseButton,
  Subtotal,
  ButtonValidar,
  ButtonDeafult,
  SeparatorLine,
  InpuTypeDate,
} from './styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import ButtonAtlaskit from '@atlaskit/button';
import DefaultLoader from '~/components/DefaultLoader';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { Field } from '@atlaskit/form';

import { Button, Col, Row } from 'react-bootstrap';
import Loja from '~/components/Loja';
import { TitulosProps, SelectProps } from './types';
import api from '~/services/api';
import Parceiros from '~/components/Parceiros';
import InputText from '~/components/Inputs/InputText';
import InputNumber from '~/components/Inputs/InputNumber';
import InputSelect from '~/components/Inputs/InputSelect';
import InputMoney from '~/components/Inputs/InputMoney';
import ToggleDefault from '~/components/ToggleDefault';
import Separator from '~/components/Separator';
import ContaBanco from '../../components/ContaBanco';
import Entidade from '~/components/Entidade';
import Categoria from '~/components/Categoria';
import { Checkbox } from '@atlaskit/checkbox';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FaBarcode, FaEraser } from 'react-icons/fa';
import { AiFillSave, AiOutlineClose } from 'react-icons/ai';
import { mod10 } from '~/utils/mod10';
import { toast } from 'react-toastify';
import { lojaUnica } from '~/utils/lojaUnica';
import FooterDefault from '~/components/FooterDefault';
import Tooltip from '@atlaskit/tooltip';
import { HotKeys, configure } from 'react-hotkeys';
import { useHotkeys } from 'react-hotkeys-hook';
import { useQueryClient } from 'react-query';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { transformAsCurrency } from '~/utils/functions';

import codigoBanco from './data/codigoBanco.json';
import {
  codBarras2LinhaDigitavel,
  identificarTipoBoleto,
  linhaDigitavel2CodBarras,
  validarBoleto,
} from '~/utils/boletos';

import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const AceitePagamento: React.FC = () => {
  const [init, setInit] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTela, setLoaderTela] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  // FORMUMALIRO ACEITE
  const [lojas, setLojas] = useState<number | Array<number>>([]);
  const [cod_pessoa, setCod_pessoa] = useState<SelectProps>({
    value: undefined,
    label: '',
  });
  const [num_docto, setNum_docto] = useState<number | undefined>(undefined);
  const [val_parcela, setVal_parcela] = useState<string | undefined>(undefined);
  const [com_aceite, setCom_aceite] = useState<boolean>(false);
  const [tipo_periodo, setTipo_periodo] = useState<SelectProps>({
    value: 0,
    label: 'Entrada',
  });
  const [dta_ini, setDta_ini] = useState<string>('');
  const [dta_fim, setDta_fim] = useState<string>('');
  // MODAL ACEITE BOLETO
  const [isValid, setIsValid] = useState<boolean>(false);
  const [initAceite, setInitAceite] = useState<boolean>(false);
  const [captura, setCaptura] = useState<string>();
  const [tipo_aceite, setTipo_aceite] = useState<SelectProps>({
    value: undefined,
    label: '',
  });
  const [dta_venc, setDta_venc] = useState<string>('');
  const [val_captura, setVal_captura] = useState<string>('0');
  const [dta_emissao, setDta_emissao] = useState<string>('');
  const [N_Fat_Dup, setN_Fat_Dup] = useState<number>(0);
  const [conta, setConta] = useState<SelectProps>({
    value: undefined,
    label: '',
  });
  const [finalizadora, setFinalizadora] = useState<SelectProps>({
    value: undefined,
    label: '',
  });
  const [categoria, setCategoria] = useState<SelectProps>({
    value: undefined,
    label: '',
  });

  const [titulosApagar, setTitulosApagar] = useState<TitulosProps[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [isTitulos, setIsTitulos] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const containerNumDoctoRef = useRef<HTMLDivElement | null>(null);
  const containerValParcelaRef = useRef<HTMLDivElement | null>(null);

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  function validationInput() {
    if (cod_pessoa.value === undefined || dta_ini === '' || dta_fim === '') {
      return true;
    }
    return false;
  }

  function validationInputModal() {
    if (
      captura === '' ||
      tipo_aceite.value === undefined ||
      val_captura === '' ||
      dta_venc === '' ||
      !(N_Fat_Dup >= 0) ||
      dta_emissao === '' ||
      conta.value === undefined ||
      finalizadora.value === undefined ||
      categoria.value === undefined
    ) {
      return true;
    }
    if (
      dta_emissao !== undefined &&
      dta_venc !== undefined &&
      createDateWithoutTimezone(dta_emissao) >
        createDateWithoutTimezone(dta_venc)
    ) {
      toast.warning(
        'Data de emissão deve ser menor ou igual à data de vencimento',
      );
      return true;
    }
    return false;
  }
  const getTitulosApagar = useCallback(async () => {
    setTitulosApagar([]);
    setInit(true);
    if (validationInput()) {
      return;
    }
    setLoader(true);

    let valParcela: number | undefined;

    if (val_parcela === '') valParcela = undefined;
    else if (typeof val_parcela === 'undefined') valParcela = undefined;
    else valParcela = transformAsCurrency(val_parcela || 0);

    const { data } = await api.get('/aceite-contas-apagar', {
      params: {
        lojas,
        cod_pessoa: cod_pessoa.value,
        num_docto:
          num_docto !== undefined && num_docto > 0 ? num_docto : undefined,
        val_parcela: valParcela,
        com_aceite,
        tipo_periodo: tipo_periodo.value,
        dta_ini,
        dta_fim,
      },
    });

    if (data.length > 0) {
      const result = data.map((item: TitulosProps) => {
        return {
          cod_parcela_pagar: item.cod_parcela_pagar,
          cod_conta_pagar: item.cod_conta_pagar,
          flg_boleto: com_aceite,
          cod_loja: item.cod_loja,
          num_docto: item.num_docto,
          num_nf: item.num_nf,
          num_serie: item.num_nf,
          des_finalizadora: item.des_finalizadora,
          cod_finalizadora: item.cod_finalizadora,
          val_parcela: item.val_parcela,
          dta_entrada: createDateWithoutTimezone(
            item.dta_entrada,
          ).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          dta_vencimento: createDateWithoutTimezone(
            item.dta_vencimento,
          ).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        };
      });
      setTitulosApagar(result);
      let sum = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of result) {
        sum = item.flg_boleto ? sum + item.val_parcela : sum + 0;
      }
      setSubTotal(sum);
      setIsTitulos(true);
    } else {
      setTitulosApagar([]);
      setSubTotal(0);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, [
    cod_pessoa,
    num_docto,
    val_parcela,
    com_aceite,
    tipo_periodo,
    dta_ini,
    dta_fim,
    lojas,
  ]);

  async function handleCheck(cod_conta_pagar: number) {
    const titulos = titulosApagar;
    const index = titulos.findIndex(
      (item) => item.cod_conta_pagar === cod_conta_pagar,
    );
    titulos[index].flg_boleto = !titulos[index].flg_boleto;
    setTitulosApagar(titulos);
    let sum = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of titulos) {
      sum = item.flg_boleto ? sum + item.val_parcela : sum + 0;
    }
    setSubTotal(sum);
  }

  function clearForm() {
    setInit(false);
    setLoader(false);
    setOpenModal(false);
    // FORMUMALIRO ACEITE
    setCod_pessoa({
      value: undefined,
      label: '',
    });
    setNum_docto(undefined);
    setVal_parcela(undefined);

    if (containerNumDoctoRef.current && containerValParcelaRef.current) {
      const inputNumDocto = containerNumDoctoRef.current.querySelector('input');
      const inputValParcela =
        containerValParcelaRef.current.querySelector('input');

      if (inputNumDocto && inputValParcela) {
        inputNumDocto.value = '';
        inputValParcela.value = '';
      }
    }

    setCom_aceite(false);
    setTipo_periodo({
      value: 0,
      label: 'Entrada',
    });
    setDta_ini('');
    setDta_fim('');
    // MODAL ACEITE BOLETO
    setIsValid(false);
    setInitAceite(false);
    setCaptura('');
    setTipo_aceite({
      value: undefined,
      label: '',
    });
    setDta_venc('');
    setVal_captura('0');
    setDta_emissao('');
    setN_Fat_Dup(0);
    setConta({
      value: undefined,
      label: '',
    });
    setFinalizadora({
      value: undefined,
      label: '',
    });
    setCategoria({
      value: undefined,
      label: '',
    });

    setTitulosApagar([]);
    setSubTotal(0);
    setIsTitulos(false);
  }

  async function onSave() {
    try {
      setLoaderTela(true);
      setInit(true);
      setInitAceite(true);
      if (com_aceite) {
        const titulosSelecionados = titulosApagar.filter(
          (item) => item.flg_boleto === false,
        );
        if (!(titulosSelecionados.length > 0)) {
          return toast.warning(
            'Nenhum título foi desmarcado para desfazer o aceite.',
          );
        }
        const data = {
          titulosApagar: titulosSelecionados,
        };
        const result = await api.post(
          '/aceite-contas-apagar/desvincular',
          data,
        );
        if (result.data.success) {
          clearForm();
          return toast.success(result.data.message);
        }
      } else {
        let capturaVal = val_captura;
        if (validationInput()) {
          return;
        }
        if (validationInputModal()) {
          setOpenModal(true);
          return;
        }
        const titulosSelecionados = titulosApagar.filter(
          (item) => item.flg_boleto === true,
        );
        if (!(titulosSelecionados.length > 0)) {
          return toast.warning('Nenhum Titulo Selecionado');
        }

        if (capturaVal.includes('.')) {
          capturaVal = capturaVal.replace('.', '');
        }

        if (Number(capturaVal.replace(',', '.')) !== subTotal) {
          setLoaderTela(false);
          return toast.warning(
            `Valor do aceite deve ser igual à soma dos títulos selecionados`,
          );
        }
        const cod_loja = lojaUnica(lojas);
        const data = {
          cod_loja,
          cod_pessoa: cod_pessoa.value,
          num_docto: N_Fat_Dup,
          dta_emissao,
          cod_categoria: categoria.value,
          cod_cc: conta.value,
          cod_barra_aceite: captura,
          val_parcela: capturaVal.replace(',', '.'),
          dta_venc,
          tipo_aceite: tipo_aceite.value,
          finalizadora,
          titulosApagar: titulosSelecionados,
        };
        const result = await api.post('/aceite-contas-apagar/vincular', data);
        if (result.data.success) {
          clearForm();
          return toast.success(result.data.message);
        }
      }
    } finally {
      setLoaderTela(false);
    }
  }

  const LinearProgressBar: React.FC = () => {
    return (
      <LinearProgress
        style={{ marginTop: '56px', background: '#c2a0f8', color: '#8850BF' }}
      />
    );
  };

  function apply() {
    setInitAceite(true);
    if (validationInputModal()) {
      return;
    }

    setOpenModal(false);
  }

  function validacaoBoleto() {
    setIsValid(true);
    const optionsAceite = [
      { label: 'Simplificado', value: 0 },
      { label: 'Boleto', value: 1 },
      { label: 'Concessionária', value: 2 },
    ];

    const capturaBoleto =
      captura?.length === 44
        ? codBarras2LinhaDigitavel(captura || '', false)
        : captura;
    const validar: any = validarBoleto(capturaBoleto || '');
    if (validar.sucesso) {
      toast.success(validar.mensagem);

      /*
    Preenchendo formulario com dados do boleto 
    */

      const aceiteObject = optionsAceite.find(
        (option) => option.value === validar.tipoAceite,
      );

      setTipo_aceite(aceiteObject || { label: '', value: undefined });
      setVal_captura(validar.valor);
      setDta_venc(validar.vencimento.toLocaleDateString('en-CA'));
    } else {
      setVal_captura('');
      setDta_venc('');
      toast.warning(validar.mensagem);

      if (validar.tipoAceite === 0) {
        setTipo_aceite({ label: 'Simplificado', value: 0 });
      }
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === 173) {
        return true;
      }
    }

    return false;
  };

  useHotkeys(
    'ctrl+alt+s',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        onSave();
      }
    },
    [],
  );

  const keyMap = {
    hkSalvar: 'ctrl+alt+s',
  };

  const keyHandlers = {
    hkSalvar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        onSave();
      }
    },
  };

  if (loaderTela) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <DefaultLoader />;
        </div>
      </Container>
    );
  }

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      {openModal && (
        <Modal key="modal" width="x-large" onClose={handleCloseModal}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Captura Aceite</ModalTitle>
            <CloseButton type="button" onClick={handleCloseModal}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            <Fragment key="modal-controlled">
              <Row>
                <Col md={10} sm={12}>
                  <InputText
                    label="Captura"
                    maxLength={51}
                    value={captura}
                    onChange={(value) =>
                      setCaptura(value.replace(/[^0-9]/g, ''))
                    }
                    isRequired
                    setInvalid={captura === ''}
                    iniInicializado={initAceite}
                  />
                </Col>
                <Col md={2} sm={12}>
                  <ButtonValidar variant="success" onClick={validacaoBoleto}>
                    VALIDAR
                  </ButtonValidar>
                </Col>
              </Row>
              {isValid && (
                <>
                  <GroupItem>
                    <span>Dados do Aceite:</span>
                    <Separator />
                  </GroupItem>
                  <Row>
                    <Col md={3} sm={12}>
                      <InputSelect
                        options={[
                          { label: 'Simplificado', value: 0 },
                          { label: 'Boleto', value: 1 },
                          { label: 'Concessionária', value: 2 },
                        ]}
                        label="Tipo do Aceite"
                        placeholder="Informe o Tipo do Aceite"
                        value={tipo_aceite}
                        onChange={(value: SelectProps) => setTipo_aceite(value)}
                        isRequired
                        setInvalid={tipo_aceite.value === undefined}
                        iniInicializado={initAceite}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <InputMoney
                        label="Valor"
                        value={val_captura}
                        onChange={(value: string) => {
                          setVal_captura(value);
                        }}
                        isRequired
                        setInvalid={val_captura === ''}
                        iniInicializado={initAceite}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <Field name="Vencimento" label="Vencimento">
                        {({ fieldProps }) => (
                          <div
                            style={{
                              borderBottom:
                                dta_venc === '' && initAceite
                                  ? '2px solid #cf1919'
                                  : '',
                            }}
                          >
                            <InpuTypeDate
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setDta_venc(e.target.value);
                              }}
                              value={dta_venc}
                              type="date"
                              style={{
                                border: '2px solid #DFE1E6',
                                background: '#f8f9fc',
                                borderRadius: '4px',
                                paddingLeft: '10px',
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    </Col>
                    <Col md={3} sm={12}>
                      <InputNumber
                        label="N.º Fat/Dup"
                        value={N_Fat_Dup}
                        onChange={(value: number) => {
                          if (value?.toString()?.length <= 10) {
                            setN_Fat_Dup(value);
                          }
                        }}
                        isRequired
                        setInvalid={!(N_Fat_Dup >= 0)}
                        iniInicializado={initAceite}
                        min={0}
                        placeholder="0000000"
                      />
                    </Col>
                  </Row>
                  <GroupItem>
                    <span>Novos Dados do Aceite</span>
                    <Separator />
                  </GroupItem>
                  <Row>
                    <Col md={3} sm={12}>
                      <Field name="Emissão" label="Emissão">
                        {({ fieldProps }) => (
                          <div
                            style={{
                              borderBottom:
                                dta_emissao === '' && initAceite
                                  ? '2px solid #cf1919'
                                  : '',
                            }}
                          >
                            <InpuTypeDate
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setDta_emissao(e.target.value);
                              }}
                              value={dta_emissao}
                              type="date"
                              style={{
                                border: '2px solid #DFE1E6',
                                background: '#f8f9fc',
                                borderRadius: '4px',
                                paddingLeft: '10px',
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    </Col>
                    <Col md={9} sm={12}>
                      <div
                        style={{
                          borderBottom:
                            conta.value === undefined && initAceite
                              ? '2px solid #cf1919'
                              : '',
                        }}
                      >
                        <ContaBanco
                          value={conta}
                          onChange={(value: SelectProps) => setConta(value)}
                          isRequired
                          loja={lojas}
                          menuPosition="fixed"
                          menuPlacement="bottom"
                          minMenuHeight={10}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} sm={12}>
                      <div
                        style={{
                          borderBottom:
                            finalizadora.value === undefined && initAceite
                              ? '2px solid #cf1919'
                              : '',
                        }}
                      >
                        <Entidade
                          value={finalizadora}
                          onChange={(value: SelectProps) =>
                            setFinalizadora(value)
                          }
                          isRequired
                          setInvalid={finalizadora.value === undefined}
                          loja={lojas}
                          isDisabled={false}
                          menuPosition="fixed"
                          menuPlacement="bottom"
                          minMenuHeight={10}
                        />
                      </div>
                    </Col>
                    <Col md={7} sm={12}>
                      <Categoria
                        tipoCategoria="Despesa"
                        value={categoria}
                        onChange={(value: SelectProps) => {
                          setCategoria(value);
                        }}
                        isRequired
                        iniInicializado={initAceite}
                        setInvalid={categoria.value === undefined}
                        menuPosition="fixed"
                        menuPlacement="bottom"
                        minMenuHeight={10}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px',
                        marginTop: '20px',
                      }}
                    >
                      <Button variant="success" onClick={apply}>
                        APLICAR
                      </Button>
                    </div>
                  </Row>
                </>
              )}
            </Fragment>
            <br />
          </ModalBody>
        </Modal>
      )}
      <Container>
        <h3>Aceite de Pagamento</h3>
        <SeparatorLine />
        <Row>
          <Col md={12} sm={12}>
            <Loja
              isMulti
              onChange={(val: number | number[]) => {
                setLojas(val);
              }}
              selectedLoja={lojas}
              isDisabled={isTitulos}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={12}>
            <Parceiros
              value={cod_pessoa}
              tipos={['Cliente', 'Fornecedor', 'Loja']}
              isRequired
              setInvalid={cod_pessoa.value === undefined}
              isDisabled={isTitulos}
              clearOptions={cod_pessoa?.value === undefined}
              iniInicializado={init}
              onChange={(value: SelectProps) => {
                setCod_pessoa(value);
              }}
            />
          </Col>

          <Col md={2} sm={12} ref={containerNumDoctoRef}>
            <InputNumber
              label="N.º Docto"
              value={num_docto}
              max={9999999999}
              min={0}
              maxLength={10}
              placeholder="0000"
              isUndefined
              isNull
              isDisabled={isTitulos}
              onChange={(value: number) => {
                if (value?.toString()?.length <= 10) {
                  setNum_docto(value);
                }
              }}
            />
          </Col>
          <Col md={2} sm={12} ref={containerValParcelaRef}>
            <InputMoney
              label="Valor"
              value={val_parcela}
              min={0.01}
              placeholder=""
              isNull
              isUndefined
              isEmpty
              isDisabled={isTitulos}
              onChange={(value: string) => {
                setVal_parcela(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <GroupItem style={{ height: '60px', marginTop: '15px' }}>
              <label
                className="label"
                htmlFor="toggle-controlled"
                style={{ marginTop: '5px' }}
              >
                Com Aceite?
              </label>
              <ToggleDefault
                size="large"
                labelText=""
                setChecked={com_aceite}
                disabled={isTitulos}
                onSwitch={() => {
                  setCom_aceite(!com_aceite);
                  if (!com_aceite) {
                    setTipo_periodo({ value: 2, label: 'Aceite' });
                  }
                }}
              />
            </GroupItem>
          </Col>
          <Col md={6} sm={12}>
            <GroupItem style={{ height: '60px', marginTop: '20px' }}>
              <label
                className="label"
                htmlFor="toggle-controlled"
                style={{
                  marginTop: '-2px',
                  marginRight: '0.3vw',
                  fontSize: '1.4vw',
                }}
              >
                Período
              </label>
              <BtnGroupContainer>
                <ButtonAtlaskit
                  key="ContaContabil"
                  className={
                    tipo_periodo.value === 0
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  value="0"
                  style={{
                    height: '37px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    borderRadius: '4px 0px 0px 4px',
                    width: '35%',
                  }}
                  type="button"
                  isDisabled={isTitulos}
                  onClick={() => {
                    setTipo_periodo({ value: 0, label: 'Entrada' });
                  }}
                >
                  Entrada
                </ButtonAtlaskit>
                <ButtonAtlaskit
                  key="reduzida"
                  className={
                    tipo_periodo.value === 1
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  style={{
                    color: '#fff',
                    height: '37px',
                    width: '30%',
                    borderLeft: '2px solid #fff',
                    borderRight: '2px solid #fff',
                  }}
                  type="button"
                  isDisabled={isTitulos}
                  onClick={() => {
                    setTipo_periodo({ value: 1, label: 'Vencimento' });
                  }}
                >
                  Vencimento
                </ButtonAtlaskit>
                <ButtonAtlaskit
                  key="desc"
                  className={
                    tipo_periodo.value === 2
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  style={{
                    borderRadius: '0px 4px 4px 0px',
                    height: '37px',
                    width: '35%',
                  }}
                  isDisabled={isTitulos}
                  type="button"
                  onClick={() => {
                    setTipo_periodo({ value: 2, label: 'Aceite' });
                  }}
                >
                  Aceite
                </ButtonAtlaskit>
              </BtnGroupContainer>
            </GroupItem>
          </Col>
          <Col md={2} sm={12}>
            <Field name="dta_emissao" label="Data de Início">
              {({ fieldProps }) => (
                <div
                  style={{
                    borderBottom:
                      dta_ini === '' && init ? '2px solid #cf1919' : '',
                  }}
                >
                  <InpuTypeDate
                    disabled={isTitulos}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setDta_ini(e.target.value);
                    }}
                    value={dta_ini}
                    type="date"
                    style={{
                      border: '2px solid #DFE1E6',
                      background: '#f8f9fc',
                      borderRadius: '4px',
                      paddingLeft: '10px',
                    }}
                  />
                </div>
              )}
            </Field>
          </Col>
          <Col md={2} sm={12}>
            <Field name="dta_emissao" label="Data do Fim ">
              {({ fieldProps }) => (
                <div
                  style={{
                    borderBottom:
                      dta_fim === '' && init ? '2px solid #cf1919' : '',
                  }}
                >
                  <InpuTypeDate
                    disabled={isTitulos}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setDta_fim(e.target.value);
                    }}
                    value={dta_fim}
                    type="date"
                    style={{
                      border: '2px solid #DFE1E6',
                      background: '#f8f9fc',
                      borderRadius: '4px',
                      paddingLeft: '10px',
                    }}
                  />
                </div>
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            <CustomButtonNovo
              disabled={isTitulos}
              onClick={getTitulosApagar}
              label="Pesquisar"
              icon={IoIosSearch}
            />
            <CustomButtonNovo
              variant="cancel"
              disabled={!isTitulos}
              onClick={() => {
                setIsTitulos(false);
              }}
              label="Cancelar"
              icon={MdOutlineCancel}
            />
            <CustomButtonNovo
              variant="clear"
              disabled={isTitulos}
              onClick={clearForm}
              label="Limpar"
              icon={FaEraser}
            />
          </div>
        </Row>
        <Separator />
        <Col md={12} sm={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vincular?</TableCell>
                  <TableCell>Loja</TableCell>
                  <TableCell>Docto</TableCell>
                  <TableCell>Núm. NF</TableCell>
                  <TableCell>Série NF</TableCell>
                  <TableCell>Finalizadora</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Entrada</TableCell>
                  <TableCell>Vencimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loader && titulosApagar.length > 0 && (
                  <>
                    {titulosApagar.map((item, index) => {
                      return (
                        <TableRow key={item.cod_conta_pagar}>
                          <TableCell
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Checkbox
                              isChecked={item.flg_boleto}
                              onChange={() => handleCheck(item.cod_conta_pagar)}
                            />
                          </TableCell>
                          <TableCell>{item.cod_loja}</TableCell>
                          <TableCell>{item.num_docto}</TableCell>
                          <TableCell>{item.num_nf}</TableCell>
                          <TableCell>{item.num_serie}</TableCell>
                          <TableCell>{item.des_finalizadora}</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(item.val_parcela)}
                          </TableCell>
                          <TableCell className="alignDate">
                            {item.dta_entrada}
                          </TableCell>
                          <TableCell className="alignDate">
                            {item.dta_vencimento}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
            {loader && <LinearProgressBar />}
            <>
              {!loader && titulosApagar.length === 0 && (
                <TableEmpty>
                  <h3>
                    Nenhum título encontrado conforme critérios informados!
                  </h3>
                </TableEmpty>
              )}
            </>
          </TableContainer>
        </Col>
        {!com_aceite && (
          <>
            <Row>
              <Col md={3} sm={12} className="mt-2">
                <button
                  type="button"
                  className="button-aceite"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  disabled={!isTitulos}
                >
                  <FaBarcode size={25} />
                  Capturar Aceite
                </button>
              </Col>
              <Col md={6} sm={12}>
                <Separator />
              </Col>
              <Col md={3} sm={12}>
                <Subtotal>
                  <h5>
                    Selecionados:{' '}
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(subTotal)}
                  </h5>
                </Subtotal>
              </Col>
            </Row>
            <Row>
              {isValid && (
                <Col md={12} sm={12}>
                  <TableContainer>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Valor</th>
                          <th>Vencimento</th>
                          <th>NºFat/Dup</th>
                          <th>Captura</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: '37px' }}>
                          <td>{tipo_aceite.label}</td>
                          <td className="alignNumber">{`R$ ${val_captura}`}</td>
                          <td className="alignDate">
                            {dta_venc &&
                              createDateWithoutTimezone(
                                dta_venc,
                              ).toLocaleDateString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                          </td>
                          <td className="alignNumber">{N_Fat_Dup}</td>
                          <td>{captura}</td>
                        </tr>
                      </tbody>
                    </table>
                  </TableContainer>
                </Col>
              )}
            </Row>
          </>
        )}

        <SeparatorLine />

        <FooterDefault codTela={173}>
          <Col md={12} sm={12} className="mt-2 d-flex justify-content-end">
            <Tooltip position="bottom" content="CTRL + ALT + S">
              <ButtonDeafult
                className="confirm"
                onClick={() => {
                  onSave();
                }}
              >
                <AiFillSave size={25} /> Gravar
              </ButtonDeafult>
            </Tooltip>
          </Col>
        </FooterDefault>
      </Container>
    </HotKeys>
  );
};

export default AceitePagamento;
